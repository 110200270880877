import React, { useContext } from 'react';
import ReactGA from 'react-ga4';
import Intercom, { update as updateIntercom } from '@intercom/messenger-js-sdk';
import './App.css';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';

import { Context as AuthContext, Store as AuthStore } from './context/Auth';
import { Display as NotificationDisplay } from './context/Notification';
import EmbedAuthProvider from './context/EmbedAuth';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Signup from './pages/Signup';
import CompleteSignup from './pages/CompleteSignup';
import SignupCheckEmail from './pages/SignupCheckEmail';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Maintenance from './pages/Maintenance';
import EmbeddedBulkVerify from './pages/EmbeddedBulkVerify';
import EmbeddedBulkVerifySteps from './pages/EmbeddedBulkVerifySteps';
import InvalidEmbeddedRoute from './pages/InvalidEmbededRoute';

// NOTE(Apaar): I chose not to use env vars for this because Env vars aren't
// particularly flexible in react anyways and it's inconvenient in this case.
ReactGA.initialize([
    { trackingId: 'G-WK8FXW2821' },
    { trackingId: 'G-SWV6BZ1KER' },
    { trackingId: 'G-R6Q7DW4DDB' },
    { trackingId: 'G-NX0QK8MD21' },
    { trackingId: 'G-9FVCJH1EJ9' },
]);

let bootedIntercom = false;

const theme = createMuiTheme({
    typography: {
        fontFamily:
            'Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Ubuntu,sans-serif',
        h1: {
            fontFamily: 'Roboto',
        },
        h2: {
            fontFamily: 'Roboto',
        },
        h3: {
            fontFamily: 'Roboto',
        },
        h4: {
            fontFamily: 'Roboto',
        },
        h5: {
            fontFamily: 'Roboto',
        },
        h6: {
            fontFamily: 'Roboto',
            fontWeight: 400,
        },
        subtitle1: {
            fontFamily: 'Roboto',
        },
        subtitle2: {
            fontFamily: 'Poppins',
            fontSize: '15px',
            fontWeight: 500,
        },
        body1: {
            fontFamily: 'Roboto',
            color: '#000',
            fontSize: '15px',
        },
        body2: {
            fontFamily: 'Roboto',
            color: '#000',
            fontSize: '13px',
            fontWeight: 400,
        },
    },
    palette: {
        type: 'light',
        background: {
            default: '#F7FAFC',
        },
        primary: {
            main: '#2f71eb',
            light: '#94BFFF',
            dark: '#7C8DB5',
        },
        secondary: {
            // main: '#9B9B9B',
            main: '#FD6270',
            contrastText: '#fff',
        },
        info: {
            main: '#2196f3',
            light: '#E0EBFB',
        },
        grey: {
            200: '#DBE9FB',
            A700: '#A3ACB9',
            A100: '#F0F6FD',
        },
    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: '35px',
            },
        },
        MuiTableCell: {
            root: {
                fontFamily: 'Roboto',
            },
        },
        MuiInputLabel: {
            root: {
                color: '#707070',
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0px 1px 2px 1px rgba(232,232,232,0.68)',
            },
        },
        MuiBackdrop: {
            root: {
                zIndex: 0,
                opacity: 0,
            },
        },
    },
});

const AuthRoute = (props: RouteProps) => {
    const { state } = useContext(AuthContext);

    if (!state.user) {
        return <Redirect to="/login" />;
    }

    const intercomParams = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'c9d33bc3',
        name: `${state.user?.firstName} ${state.user?.lastName}`,
        email: state.user?.email,
        created_at: state.user
            ? state.user?.createdAt.getTime() / 1000
            : undefined,
        user_id: state.user?.id,
        user_hash: state.user?.intercomHash,
    };

    if (!bootedIntercom) {
        // this will boot up intercom
        Intercom(intercomParams);
    } else {
        updateIntercom(intercomParams);
    }

    bootedIntercom = true;

    return <Route {...props} />;
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <AuthStore>
                    <NotificationDisplay>
                        <Switch>
                            <AuthRoute path="/dashboard">
                                <Dashboard />
                            </AuthRoute>

                            <Route
                                path="/forgot_password"
                                component={ForgotPassword}
                            />

                            <Route
                                path="/reset_password/:token"
                                component={ResetPassword}
                            />

                            <Route
                                path="/signup/:signupID"
                                component={CompleteSignup}
                            />

                            <Route path="/invite/:userID" component={Signup} />

                            <Route path="/signup" component={Signup} />

                            <Route
                                path="/signup_check_email"
                                component={SignupCheckEmail}
                            />

                            <Route
                                path="/login/impersonate"
                                render={() => {
                                    return <Login allowImpersonation />;
                                }}
                            />

                            <Route path="/login" component={Login} />

                            <Route
                                path="/maintenance"
                                component={Maintenance}
                            />

                            <Route path="/embed">
                                <EmbedAuthProvider>
                                    <Switch>
                                        <Route
                                            path="/embed/bulk_verify/create"
                                            component={EmbeddedBulkVerifySteps}
                                        />

                                        <Route
                                            path="/embed/bulk_verify"
                                            component={EmbeddedBulkVerify}
                                        />

                                        <Route
                                            path="*"
                                            component={InvalidEmbeddedRoute}
                                        />
                                    </Switch>
                                </EmbedAuthProvider>
                            </Route>

                            <Redirect from="/" to="/login" />
                        </Switch>
                    </NotificationDisplay>
                </AuthStore>
            </Router>
        </ThemeProvider>
    );
}

export default App;
