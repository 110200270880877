import React, {
    createContext,
    useReducer,
    PropsWithChildren,
    Dispatch,
    useContext,
    useCallback,
} from 'react';

import { convertDatesInPlace } from '../services/util';

interface APIKey {
    value: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface User {
    id: string;
    organization: string;
    firstName: string;
    lastName: string;
    email: string;
    stripeCustomerId: string;
    permissions: number;
    pendingInvite: boolean;
    embeddedSecret?: string;
    keys: APIKey[];
    createdAt: Date;
    updatedAt: Date;
    intercomHash?: string;
}

export enum ActionType {
    LOGIN = 'login',
    UPDATE_USER = 'update',
    LOGOUT = 'logout',
    TEMP_LOGIN = 'temp_login',
}

export interface LoginPayload {
    user: User;
    token: string;
}

interface LoginAction {
    type: ActionType.LOGIN;
    payload: LoginPayload;
}

interface UpdateUserAction {
    type: ActionType.UPDATE_USER;
    user: User;
}

interface LogoutAction {
    type: ActionType.LOGOUT;
}

interface TempLoginAction {
    type: ActionType.TEMP_LOGIN;
    payload: LoginPayload;
}

type Action = LoginAction | UpdateUserAction | LogoutAction | TempLoginAction;

export interface State {
    user?: User;
    token?: string;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ActionType.LOGIN:
            // A regular login should _NEVER_ have a secret
            delete action.payload.user.embeddedSecret;

            localStorage.setItem('user', JSON.stringify(action.payload.user));
            localStorage.setItem('token', action.payload.token);

            return {
                ...state,
                ...action.payload,
            };

        case ActionType.UPDATE_USER:
            localStorage.setItem(
                'user',
                JSON.stringify({
                    ...state.user,
                    ...action.user,
                })
            );

            return {
                ...state,
                user: action.user,
            };

        // Save the login details in state but not local storage
        case ActionType.TEMP_LOGIN:
            return {
                ...state,
                ...action.payload,
            };

        case ActionType.LOGOUT:
            localStorage.removeItem('user');
            localStorage.removeItem('token');

            const anyWindow = window as any;
            if (anyWindow.Intercom) {
                anyWindow.Intercom('shutdown');
            }
            return {};
    }
};

export const Context = createContext<{
    state: State;
    dispatch: Dispatch<Action>;
    dispatchLogin: (payload: LoginPayload) => void;
    dispatchTempLogin: (payload: LoginPayload) => void;
}>({
    state: {},
    dispatch: () => {},
    dispatchLogin: () => {},
    dispatchTempLogin: () => {},
});

export const Store = (props: PropsWithChildren<{}>) => {
    const existingUser: User = JSON.parse(
        localStorage.getItem('user') || 'null'
    );

    convertDatesInPlace(existingUser);

    // Remove the secret if someone put it in storage themselves for fun
    delete existingUser?.embeddedSecret;

    const existingToken: string = localStorage.getItem('token') || '';

    const [state, dispatch] = useReducer(reducer, {
        user: existingUser,
        token: existingToken,
    });

    const dispatchLogin = useCallback((payload: LoginPayload) => {
        dispatch({ type: ActionType.LOGIN, payload });
    }, []);

    const dispatchTempLogin = useCallback((payload: LoginPayload) => {
        dispatch({ type: ActionType.TEMP_LOGIN, payload });
    }, []);

    return (
        <Context.Provider
            value={{ state, dispatch, dispatchLogin, dispatchTempLogin }}
        >
            {props.children}
        </Context.Provider>
    );
};

export const useAuthContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error(
            'useAuthContext can only be used within a `<Store />` provider.'
        );
    }

    return context;
};
